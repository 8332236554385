body {
  background-color: #1e1e1e !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  opacity: 0.4;
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 0.8;
}
